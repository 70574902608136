.loader-wrapper {
  opacity: 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.loader {
  height: 80px;
  width: 80px;
}
